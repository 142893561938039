<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18662538235">1-866-253-8235</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site.bh-ski-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
      <a href="tel:18662538235" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site.bh-ski-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>Billets de ski 2021/22</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Les billets réservés d’avance sont date spécifique et valables à compter de la première journée d’utilisation prévue. 1 journée de pause est permis pour les billets 3 jours, 4 jours, 5 jours, 6 jours ou 7 jours. Pas de pause permis pour les billets 2 jours.</li>
          <li class="regular-text">Tarification propre du lundi au vendredi, le samedi et dimanche et à la haute saison du 27 décembre 2021 au 2 janvier 2022 et du 19 février au 24 février 2022.</li>
        </ul>
        
        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">Doit être acheté au moins 48 heures d’avance, en ligne ou par téléphone. 1-888-738-1777</li>
          <li class="regular-text">Règle d'âge pour toutes les catégories d'âge: âge au moment de l'achat.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">Vous devez récupérer votre billet au Service à la clientèle, au Centre Aventure du Sommet des Neiges, à la billetterie (carte d'identité avec photo obligatoire) ou aux bornes d’impression la veille ou le jour même de votre arrivée.</li>
          <li class="regular-text">Si vous avez réservé ce produit avec votre hébergement sur le site, le billet vous sera livré directement à votre hôtel.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <p class="regular-text">Remboursable jusqu’à 72 heures avant la première journée d’utilisation prévue. Non remboursable après ce délai. Annulation ou modification à valeur inférieure (à la Station seulement): crédit sur carte cadeau montagne (applicable à l’achat de billets de ski, École sur neige, location, golf ou certaines activités), avec frais de 25 $ plus redevance et taxes. Modification à valeur égale ou supérieur sans frais en tout temps</p>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">Produit et rabais incessibles (ne peuvent être transférés d'une personne à une autre).</p>

        <div class="table-wrap">
          <div class="table">
            <div class="row">
              <div class="cell one"></div>
              <div class="cell three full"><p class="regular-text">Jusqu'au 30 novembre 2021</p></div>
            </div>
            <div class="row">
              <div class="cell one"></div>
              <div class="cell three light"><p class="regular-text">Lundi au vendredi</p></div>
              <div class="cell three light"><p class="regular-text">Samedi et dimanche</p></div>
              <div class="cell half light"><p class="regular-text short-lh">Haute saison<br><span>27 décembre au 2 janvier 2022 et 19 février au 24 février 2022</span></p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Adulte (18-69)</p></div>
              <div class="cell three soft"><p class="regular-text">83 $</p></div>
              <div class="cell three soft"><p class="regular-text">95 $</p></div>
              <div class="cell half soft"><p class="regular-text">129 $</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Ainé (70+)</p></div>
              <div class="cell three soft"><p class="regular-text">72 $</p></div>
              <div class="cell three soft"><p class="regular-text">82 $</p></div>
              <div class="cell half soft"><p class="regular-text">113 $</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Jeune (13-17)</p></div>
              <div class="cell three soft"><p class="regular-text">56 $</p></div>
              <div class="cell three soft"><p class="regular-text">64 $</p></div>
              <div class="cell half soft"><p class="regular-text">85 $</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Enfant (5-12)</p></div>
              <div class="cell three soft"><p class="regular-text">48 $</p></div>
              <div class="cell three soft"><p class="regular-text">54 $</p></div>
              <div class="cell half soft"><p class="regular-text">72 $</p></div>
            </div>
          </div>
        </div>

        <h2 class="small-title">Passeport vaccinal et preuve de vaccination</h2>
        <p class="regular-text">Pour les détenteurs de passes <a href="https://www.tremblant.ca/planifiez/billets-passes/passes-de-saison-de-ski/passes-ikon?sc_lang=fr" target="_blank">Ikon</a> et <a href="https://www.tremblant.ca/planifiez/billets-passes/passes-de-saison-de-ski/passe-tonik?sc_lang=fr" target="_blank">Tonik</a> ainsi que de <a href="https://www.tremblant.ca/planifiez/billets-passes/billets-de-ski/carte-latitude?sc_lang=fr" target="_blank">cartes Latitude</a>, la vérification du passeport vaccinal ou de la preuve de vaccination combinée à une pièce d’identité se fera une seule fois, avant votre première visite, pour accéder aux remontées mécaniques cette saison. Deux options de validation seront disponibles dès le 6 novembre:</p>
        <ul>
          <li class="regular-text">en personne au Chalet des voyageurs</li>
          <li class="regular-text">par vidéoconférence en prenant rendez-vous sur notre plateforme virtuelle <a href="https://kiosk.ca1.qless.com/kiosk/app/home/19527" target="_blank">tremblant.ca/rdv</a></li>
        </ul>
        <p class="regular-text">Notez que pour assurer le respect des dernières annonces de la Santé publique du Québec, les passes des détenteurs de 13 ans et plus sont dorénavant désactivées, et ne seront réactivées que lorsque cette validation aura été effectuée.</p>
        <p class="regular-text">Pour les détenteurs de billets (SkiMax, multi-jours, entrepôt, groupes, autres), la vérification se fera en personne à l’un de nos comptoirs de billetteries. Pour les <a href="https://www.tremblant.ca/planifiez/billets-passes/billets-de-ski?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=fr" target="_blank">billets multi-jours</a>, une seule validation sera nécessaire pour l’ensemble des journées de validité de votre billet.</p>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">2021/22 Winter Lift Tickets<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">Multi-day tickets are date specific and valid starting from the first planned day of use. 1 day of break is permitted for 3-day tickets, 4-day tickets, 5-day tickets, 6-day tickets or 7-day tickets. No break is permitted for 2-day tickets. </li>
          <li class="regular-text">Rates specific Monday to Friday, Saturday and Sunday and hi-season from December 27, 2021 to January 2, 2022 and February 19 to 24, 2022.</li>
        </ul>

        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Tickets must be purchased 48 hours in advance online or by phone. 1-888-738-1777</li>
          <li class="regular-text">Age rule for all age categories: age at time of purchase.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">You must pick-up your ticket at our Guest Services Desk, the Adventure Center of the Sommet des Neiges hotel, ticket offices (photo ID required) or ticket printing kiosks the before or day of your arrival</li>
          <li class="regular-text">If you purchased your Multi-day Ticket with onsite lodging, it will be delivered to your hotel.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <p class="regular-text">Can be refunded up to 72 hours prior to first planned day of use. Not refundable past this period. Cancellation or modification to a lesser value product (at the resort only): credit loaded on a mountain card (applicable on the purchase of lift tickets, Snow School, rental, golf or some activities), incurs a $25 fee plus royalty and taxes. Changes for an equal or greater value product do not incur fees.</p>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">Non-transferable (product and discounts cannot be transferred from one person to the next).</p>

        <div class="table-wrap">
          <div class="table">
            <div class="row">
              <div class="cell one"></div>
              <div class="cell three full"><p class="regular-text">Until October 19, 2021</p></div>
            </div>
            <div class="row">
              <div class="cell one"></div>
              <div class="cell three light"><p class="regular-text">Monday to Friday</p></div>
              <div class="cell three light"><p class="regular-text">Saturday and Sunday</p></div>
              <div class="cell half light"><p class="regular-text short-lh">High season<br><span>December 27 to January 2, 2022 and February 19 to February 24, 2022</span></p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Adult (18-69)</p></div>
              <div class="cell three soft"><p class="regular-text">$83</p></div>
              <div class="cell three soft"><p class="regular-text">$95</p></div>
              <div class="cell half soft"><p class="regular-text">$129</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Senior (70+)</p></div>
              <div class="cell three soft"><p class="regular-text">$72</p></div>
              <div class="cell three soft"><p class="regular-text">$82</p></div>
              <div class="cell half soft"><p class="regular-text">$113</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Youth (13-17)</p></div>
              <div class="cell three soft"><p class="regular-text">$56</p></div>
              <div class="cell three soft"><p class="regular-text">$64</p></div>
              <div class="cell half soft"><p class="regular-text">$85</p></div>
            </div>
            <div class="row">
              <div class="cell one darker"><p class="regular-text">Child (5-12)</p></div>
              <div class="cell three soft"><p class="regular-text">$48</p></div>
              <div class="cell three soft"><p class="regular-text">$54</p></div>
              <div class="cell half soft"><p class="regular-text">$72</p></div>
            </div>
          </div>
        </div>

        <h2 class="small-title">Vaccine passport and proof of vaccination</h2>
        <p class="regular-text">For <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-season-passes/ikon-passes" target="_blank">Ikon Pass</a>, <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-season-passes/tonik-pass" target="_blank">Tonik Pass</a> and <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-lift-tickets/latitude-card" target="_blank">Latitude Card</a> holders: Vaccine passport scanning or proof of vaccination verification and identification, will only be necessary one time. On November 6, Tremblant will begin offering two methods of this vaccination validation:</p>
        <ul>
          <li class="regular-text">In-person validation at the Chalet des Voyageurs</li>
          <li class="regular-text">Videoconference validation (for which you can book your appointment on our virtual platform <a href="https://kiosk.ca1.qless.com/kiosk/app/home/19527" target="_blank">tremblant.ca/rdv</a>)</li>
        </ul>
        <p class="regular-text">Please note: To ensure compliance with the latest announcements from Quebec Public Health authorities, Passes and Cards of holders aged 13 years and older have now been deactivated for use at Tremblant and will only be reactivated upon proper vaccination validation.</p>
        <p class="regular-text">Day ticket holders (Ski-max, multi-day, and others) will need to validate vaccination on-site. A single validation will be necessary for <a href="https://www.tremblant.ca/plan/tickets-and-passes/winter-lift-tickets?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=en" target="_blank">multi-day tickets</a>.</p>

     </div>
      
    </section>

    <footer-bar></footer-bar>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
